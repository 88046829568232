<template>
    <v-container fluid>
        <v-row row wrap>
            

            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>


            <template v-if="activeRenewalProduct!=null">
                <v-col cols="12">
                <template v-if="activeRenewalProduct.status=='Active'">
                    <h4 class="ma-3"> Membership renewal is {{activeRenewalProduct.status=='Active'?"AVAILABLE for": "HIDDEN from"}} members </h4>
                </template>
                <template v-else>
                    <h4> Membership renewal product is created but HIDDEN from members </h4>
                    
                </template>
                
                <v-btn outlined class="ma-3" @click="editMembership"> View/Edit Membership Details</v-btn>
                <v-btn outlined class="ma-3" @click="changeRenewalStatus"> {{activeRenewalProduct.status == "Draft" ? "SHOW": "HIDE"}} renewal option for members</v-btn>
                </v-col>
            </template>

            <template v-else>
                <v-col cols="12">
                    <h4 class="ma-3"> Membership renewal is not currently setup for next year.  </h4>
                    <v-btn outlined class="ma-3" @click="viewMembership=!viewMembership"> Setup Membership Renewal</v-btn>
                </v-col>
            </template>

            <template v-if="product!={} && viewMembership">
    

            <v-col cols="12">
                <h4 class="mb-3">Setup membership product:</h4>
                <VFormBase
                :model="product" 
                :schema="productSchema"
                @click="handleEvent($event, 'variation')"
                ></VFormBase>
            </v-col>

            <v-col cols="12">
                <h4 class="mb-3">Add options on the purchase page:</h4>
                <div v-for="option in product.options" v-bind:key=option.name>
                <VFormBase
                :model="option" 
                :schema="optionSchema" 
                @click="handleEvent($event, option.name)"
                ></VFormBase>
                </div>
                <v-btn @click="product.options.push({name:('Option '+(product.options.length+1)), choices:[{value:' '}]})" text left-icon="mdi-plus">Add Option</v-btn>
            </v-col>

            <v-col cols="12">
                <h4 class="mb-3">Edit the terms:</h4>
                <VFormBase
                :model="product" 
                :schema="termsSchema" 
                @click="handleEvent($event, 'terms')"
                ></VFormBase>
            </v-col>

            <v-col class="pl-3">
                <v-btn
                    outlined
                    @click="saveMembershipProduct"
                    > Save Membership </v-btn>
            </v-col>

            </template>



            </template>
        </v-row>
    </v-container>
</template>

<script>
import VFormBase from 'vuetify-form-base'
import axios from 'axios'
import MembershipMixins from '@/mixins/MembershipMixins.js'
export default {
    data () {
        return {
            extendSwitch: false,
          // loading: false,
            terms: [],
            draftProduct: null,
            viewMembership: false,
        }
    },
    components: {VFormBase},
    props: ['member'],
    mixins: [MembershipMixins],
    mounted () {
        this.getMembershipProducts()
        this.draftProduct = this.defaultProduct
    },
    computed: {
        product () {
            if(this.draftProduct != null)
                return this.draftProduct
            else return this.defaultProduct
        },

        defaultProduct () {
            return {
                name: "Society Membership " + this.nextYear,
                variations: [
                    {
                        name: "Saguaro Member Annual Fee",
                        price: "100",
                    },
                                        {
                        name: "Keystone Member Annual Fee",
                        price: "150",
                    },
                ],
                orderEnd: this.thisYear + "-11-30",
                orderExtension: this.thisYear + "-12-31",
                orderStart: this.thisYear + "-11-01",
                pickupEnd: this.nextYear + "-12-31",
                pickupStart: this.nextYear + "-01-01",
               // price: "100",
                productId: "Society Membership " + this.nextYear, //hide, same as name
                category: "Membership", //hide
                limit: "1", //hide
                size: "", //hide
                status: "Draft", //hide
                options: [{
                    name: "Tee Shirt Size",
                    required:true,
                    choices:[
                        {value: "XS"},
                        {value: "S"},
                        {value: "M"},
                        {value: "L"},
                        {value: "XL"},
                        {value: "XXL"},
                        ]
                    }],
                terms: [
                    {value:"Member must be 21 years of age or older at the time of purchase of membership. " +
                    "Government-issued ID must be presented along with membership card to collect " + 
                    "membership benefits"},

                    {value:"Membership fee plus sales tax is due at time of purchase and is non-refundable"},
                    
                    {value:"Member benefits are those published at www.wrenhousebrewing.com/saguaro-society " + 
                    "and valid from January 1, " + this.nextYear + " through December 31, " + this.nextYear},

                    {value:"Taproom discount applies to the member only. Member can use discount for yourself " + 
                    "and one other person with you."},

                    {value:"One person per membership and one membership per person"},

                    {value:"Wren House does not ship beer. All member pre-orders must be picked up at " + 
                    "the Taproom during normal business hours by member or by designated proxy"},

                    {value:"Bottles and cans purchased through membership may not be resold (although " + 
                    "they may be traded for in-kind beer subject to local law)"},

                    {value:"Wren House reserves the right at its sole discretion to terminate and refund " + 
                    "the unused portion of any membership for tomfoolery, shenanigans, or a general " + 
                    "breaking of the rules"},
                ]
            }
        },
        thisYear () {
            return new Date().getFullYear()
        },
        nextYear () {
            return (new Date().getFullYear()+1)
        },
        
        productSchema(){
            return {
                name: { type: "text", label: "Product Name", col: 12, class: "ma-0 ml-2"},
                variations: {
                    type: 'array',
                    label: "Variations",
                    col: 12,
                    schema: {
                        name: {
                            type: "text", dense: true,
                            label: "Variation Name",
                            col: 8,
                            class: "ma-0 ml-5 pb-1 pt-0 pl-0 pr-0",
                        }, 
                        price: {
                            type: "number", dense: true,
                            label: "Price",
                            col: 2,
                            class: "ma-0 ml-5 pb-1 pt-0 pl-0 pr-0",
                        },
                        delete: { type: "btn", col:1, icon: true, iconCenter: "clear", tooltip:"Delete Variation", disabled: this.readonly},

                    },
                },
                add: { type: "btn", icon: false, col: 3, iconLeft: "add", text: true, label: "Add Variation", class: "ml-5 mb-5"},
            //    price: { type: "number", label: "Price", col: 12, class: "ma-0 ml-2"},
                orderStart: { type: "text", ext: "Date", label: "Sale Start", col: 12, class: "ma-0 ml-2", dense: true},
                orderEnd: { type: "text", ext: "Date", label: "Sale End", col: 12, class: "ma-0 ml-2", dense: true},
                orderExtension: { type: "text", ext: "Date", label: "Sale Extension", col: 12, class: "ma-0 ml-2", dense: true},
                pickupStart: { type: "text", ext: "Date", label: "Merch Pickup Start", col: 12, class: "ma-0 ml-2", dense: true},
                pickupEnd: { type: "text", ext: "Date", label: "Merch Pickup End", col: 12, class: "ma-0 ml-2", dense: true},
            }
        },
        
        optionSchema() { 
            return {
                    name: {
                        type: "text", 
                        label: "Option Name",
                        col: 6,
                        class: "ma-0 ml-2 pb-1 pt-0 pl-0 pr-0",
    
                    }, 
                    required: {
                        type: "checkbox", 
                        label: "A Choice is Required",
                        small: true,
                        col: 6,
                        class: "ma-0 ml-0 pb-1 pt-0 pl-0 pr-0",

                    }, 
                    choices: {
                        type: 'array',
                        label: "Selection Choices",
                        schema: {
                            value: {
                                type: "text", dense: true,
                                label: "Choice",
                                col: 10,
                                class: "ma-0 ml-5 pb-1 pt-0 pl-0 pr-0",
                            }, 
                            delete: { type: "btn", col:1, icon: true, iconCenter: "clear", tooltip:"Delete Choice", disabled: this.readonly},

                        },
                    },
                    add: { type: "btn", icon: false, col: 3, iconLeft: "add", text: true, label: "Add Choice", class: "ml-5 mb-5"},
            }
        },
        termsSchema() { 
            return {
                    terms: {
                        type: 'array',
                        label: "Terms",
                        col: 12,
                        schema: {
                            value: {
                                type: "textarea", dense: true,
                                label: "Line",
                                col: 10,
                                class: "ml-3", outlined:true, rows:"2",
                            }, 
                            delete: { type: "btn", col:1, icon: true, iconCenter: "clear", tooltip:"Delete Line", disabled: this.readonly},

                        },
                    },
                    add: { type: "btn", icon: false, col: 3, iconLeft: "add", text: true, label: "Add Line", class: "ml-5 mb-5"},
            }
        }
    },
    methods: {
        editMembership(){
            this.draftProduct = this.activeRenewalProduct
            this.viewMembership=!this.viewMembership
        },
        async createSquareCustomerProfile () {
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('createsquarecustomers')
            let response = await functionsCall(
                {
                    uid: uid, 
                    users: [this.member]
                }
            )
            return response 
        },
        addItem (name) {
            if(name == 'terms'){
                this.draftProduct.terms.push({value:""})
            }
            if(name == 'variation'){
                this.draftProduct.variations.push({name:"", price:""})
            }
            else{
                this.draftProduct.options.forEach(option => {
                    if(option.name == name){
                        option.choices.push({value: " "})
                    }
                })   
            }
        },
        removeItem(name, index){
            if(name == 'terms'){
                this.draftProduct.terms.splice(index,1)
            }
            if(name == 'variation'){
                this.draftProduct.variations.splice(index,1)
            }
            else{
                this.draftProduct.options.forEach(option => {
                    if(option.name == name){
                        option.choices.splice(index,1)
                    }
                })   
            }
        },
        handleEvent ( event, name ) {
            console.log(name)
            if(event.key == "add"){
                this.addItem(name)
            }
            if(event.key == "delete"){
                this.removeItem(name, event.params.index)
            }
        },

        saveMembershipProduct(){
            this.loading = true
            this.viewMembership = false
            let self = this
            let uid = this.$store.state.user.uid
            const functionServer = ENV.functionsServer()
            return new Promise( function(resolve) {
                axios.post(functionServer + "createmembershipproduct", {uid: uid, product: self.draftProduct})
                .then( function(response) {
                    let newProduct = Object.assign({},self.draftProduct)
                    let variationIds = response.data.variationIds
                    if(variationIds.length == newProduct.variations.length){
                        for(var i = 0; i < response.data.variationIds.length; i++){
                            newProduct.variations[i].squareId = response.data.variationIds[i]
                        }
                    }
                    else{
                        console.log("Error: length of Variation Ids do not match")
                    }
                    newProduct = self.convertSchemaToDatabase(newProduct)
                    FIREBASE.db.collection("products").doc(newProduct.productId)
                    .set(newProduct)
                    .then( () => {
                        self.getMembershipProducts()
                        .then( () => {
                            self.loading = false
                            resolve()
                        })
                    })
                    .catch( error => {
                        console.log(error)
                        self.loading = false
                    })

                })
                .catch (error => {
                    console.log(error)
                })
            })  
        },
        changeRenewalStatus() {
            let self = this
            self.loading = true
            let newStatus = "Draft"
            if(this.product.status == "Draft")
                newStatus = "Active"
            FIREBASE.db.collection("products").doc(self.product.productId)
            .update({status: newStatus})
            .then( () => {
                self.getMembershipProducts()    
            })
            .catch( error => {
                console.log(error)
                self.loading = false
            })
        }
    }
}
</script>