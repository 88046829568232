//import moment from 'moment-timezone'
//import dayjs from 'dayjs'
import { mapState, mapMutations} from 'vuex'
export default {
    data () {
        return {
           // loading: false,
            renewalProducts: [],
        }
    },
    computed: {
        ...mapState([
            'loading'
        ]),
        activeRenewalProduct () {
            let today = new Date().toISOString()
            var match = null
            this.renewalProducts.forEach( product => {
                if(product.orderStart <= today && product.orderEnd >= today){
                    match = product
                }
            })
            if(match != null && match.options != undefined && match.options.length > 0){
                if(typeof match.options[0].choices[0] == 'string'){
                    match = this.convertDatabaseToSchema(match)
                }
            }
            return match
        },
        activeInviteProduct (){
            let today = new Date().toISOString()
            var match = null
            this.renewalProducts.forEach( product => {
                if(product.orderStart <= today){
                    match = product
                }
            })
            if(match != null && match.options != undefined && match.options.length > 0){
                if(typeof match.options[0].choices[0] == 'string'){
                    match = this.convertDatabaseToSchema(match)
                }
            }
            console.log(   match)
            return match
        },
        activeInviteProductYear() {
            if(this.activeInviteProduct == null)
                return null
            // Split the string into an array of words
            const words = this.activeInviteProduct.name.split(' ');
            // Iterate through the words in reverse order
            for (let i = words.length - 1; i >= 0; i--) {
              // Check if the word is a number
              if (!isNaN(words[i])) {
                return parseInt(words[i]);
              }
            }
            // If no year is found, return null
            return null;
        },
    },

    methods: {
        ...mapMutations([
            'setLoading'
        ]),

        /*
        * MEMBERSHIP RENEWAL PRODUCT AND RELEATED FUNCTIONS
        */

        getMembershipProducts () {
            this.setLoading(true)
            this.renewalProducts = []
            return FIREBASE.db.collection("products").where('category', '==', 'Membership') 
            .get()
            .then( query => {
                query.forEach( doc => {
                    this.renewalProducts.push(doc.data())
                })
                this.setLoading(false)
            })
            .catch(function(error) {
                console.log(error)
                this.setLoading(false)
            })
        },
        convertDatabaseToSchema(product){
            for(var i = 0; i < product.options[0].choices.length; i++){
                var val = product.options[0].choices[i]
                val = {value: val}
                product.options[0].choices[i] = val
            }
            for(var i = 0; i < product.terms.length; i++){
                var val = product.terms[i]
                val = {value: val}
                product.terms[i] = val
            }
            product.orderEnd = product.orderEnd.substring(0,10)
            product.orderExtension = product.orderExtension.substring(0,10)
            product.orderStart = product.orderStart.substring(0,10)
            product.pickupEnd = product.pickupEnd.substring(0,10)
            product.pickupStart = product.pickupStart.substring(0,10)
            return product
        },
        convertSchemaToDatabase(product){
            for(var i = 0; i < product.options[0].choices.length; i++){
                var val = product.options[0].choices[i]
                val = val.value
                product.options[0].choices[i] = val
            }
            for(var i = 0; i < product.terms.length; i++){
                var val = product.terms[i]
                val = val.value
                product.terms[i] = val
            }
            product.orderEnd = product.orderEnd.substring(0,10) + " 11:59"
            product.orderExtension = product.orderExtension.substring(0,10) + " 11:59"
            product.orderStart = product.orderStart.substring(0,10) + " 08:00"
            product.pickupEnd = product.pickupEnd.substring(0,10) + " 17:00"
            product.pickupStart = product.pickupStart.substring(0,10) + " 12:00"

            return product
        },

    }
}